import { Session } from "@ory/client"
import dayjs from "dayjs"
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import APIConfig from "@/services/config"
import { orySDK } from "@/services/ory"
import { UserMetadataPublic } from "@/services/types"

export interface AuthContext {
  checkSession: () => Promise<Session | undefined>
  isAuthenticated: boolean
  logout: () => Promise<void>
  metadataPublic: UserMetadataPublic
  session: Session | undefined
}

export const AuthContext = createContext<AuthContext | undefined>(undefined)
const STORAGE_KEY = "session"
const STORAGE = localStorage
const DEFAULT_METADATA = { role: "", org_id: "" }

const gotoLogin = () =>
  window.location.replace(`${APIConfig.kratosApi}/self-service/login/browser`)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | undefined>(undefined)
  const [metadataPublic, setMetadataPublic] =
    useState<UserMetadataPublic>(DEFAULT_METADATA)

  useEffect(() => {
    // Check session on initial load and handle redirection if needed
    const validateSession = async () => {
      if (window.location.pathname !== "/login") {
        await checkSession()
      }
    }

    validateSession()
  }, [])

  const logout = useCallback(async () => {
    try {
      // Invalidate the session using Ory SDK
      const { data: flow } = await orySDK.createBrowserLogoutFlow()
      // Use the received token to "update" the flow and thus perform the logout
      await orySDK.updateLogoutFlow({
        token: flow.logout_token,
      })

      // Clear storage and reset user state
      STORAGE.removeItem(STORAGE_KEY)
      setSession(undefined)

      // Redirect to login or home page after logging out
      gotoLogin()
    } catch (error) {
      console.error("Logout failed", error)
    }
  }, [])

  const checkSession = async (): Promise<Session | undefined> => {
    // Check if the session is already stored
    const storedSession = STORAGE.getItem(STORAGE_KEY)
    if (storedSession) {
      const parsedSession: Session = JSON.parse(storedSession)

      // Check if the session has expired
      if (dayjs().isAfter(dayjs(parsedSession.expires_at))) {
        STORAGE.removeItem(STORAGE_KEY)
        setSession(undefined)
        setMetadataPublic(DEFAULT_METADATA)
        return undefined
      }

      setSession(parsedSession)
      setMetadataPublic(
        parsedSession.identity?.metadata_public as UserMetadataPublic,
      )
      return parsedSession
    }

    // If no stored session, check session via Ory SDK
    try {
      const sessionResponse = await orySDK.toSession()

      if (sessionResponse.status === 200) {
        const { data: session } = sessionResponse
        setSession(session)
        setMetadataPublic(
          session.identity?.metadata_public as UserMetadataPublic,
        )
        STORAGE.setItem("session", JSON.stringify(session))
        return session
      } else {
        return undefined
      }
    } catch (error) {
      console.error("Session check failed", error)
      gotoLogin()
      return undefined
    }
  }

  return (
    <AuthContext.Provider
      value={{
        checkSession,
        isAuthenticated: !!session,
        logout,
        metadataPublic,
        session,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
