import React from "react"

const SwiftLogo = () => {
  return (
    <svg
      role="swift-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="174.741"
      height="76.366"
      viewBox="0 0 174.741 76.366"
    >
      <g id="Page-1" transform="translate(-0.5 -0.3)">
        <g id="logo-lockup" transform="translate(0.5 0.3)">
          <g id="Group" transform="translate(0 0)">
            <path
              id="Path"
              d="M44.5,43.243l1.834-2.668A14.608,14.608,0,0,0,55,43.743c3,0,5.169-1.5,5.169-4v-.167c0-2.5-3-3.5-6.169-4.335-3.835-1.167-8.17-2.5-8.17-7V28.07c0-4.335,3.5-7.17,8.5-7.17a17.674,17.674,0,0,1,9,2.668L61.674,26.4a14.058,14.058,0,0,0-7.336-2.334c-3,0-4.835,1.5-4.835,3.668V27.9c0,2.334,3,3.335,6.336,4.335,3.835,1.167,8,2.668,8,7.17v.167c0,4.669-3.835,7.5-8.837,7.5A17.963,17.963,0,0,1,44.5,43.243Z"
              transform="translate(29.698 13.948)"
              fill="#343e48"
            />
            <path
              id="Path-2"
              data-name="Path"
              d="M56.9,21.367h4l6.5,19.842L74.074,21.2h3l6.67,20.009,6.5-19.842h3.835l-8.67,25.011H82.244l-6.67-19.508-6.67,19.508H65.57Z"
              transform="translate(37.974 14.148)"
              fill="#343e48"
            />
            <path
              id="Path-3"
              data-name="Path"
              d="M87.267,28.773V23.437A8.783,8.783,0,0,1,89.434,17.1a7.431,7.431,0,0,1,5.336-2,9.585,9.585,0,0,1,4,.667v3.168a14.182,14.182,0,0,0-3.668-.667c-2.835,0-4.335,1.667-4.335,5.336v2H98.6v3.168H90.768V50.282H87.1V28.773Z"
              transform="translate(58.129 10.077)"
              fill="#343e48"
            />
            <path
              id="Path-4"
              data-name="Path"
              d="M99.3,42.644V27.638H95.8V24.3h3.5V16.8h3.668v7.5h7.837v3.335H102.97V42.144c0,3,1.667,4.168,4.168,4.168a7.776,7.776,0,0,0,3.668-.834v3.168a9.448,9.448,0,0,1-4.5,1C102.3,49.647,99.3,47.647,99.3,42.644Z"
              transform="translate(63.935 11.212)"
              fill="#343e48"
            />
            <g id="Path-5" data-name="Path">
              <path
                id="Path_5"
                data-name="Path 5"
                d="M27.114,35.777c0-.333-.167-.167-.667,0-5,3.668-6.169,4.5-11.838,5.169-1.334.167,2.5,1.334,5.836,2a16.917,16.917,0,0,1-2.835,7.5C16.776,51.951,27.281,46.782,27.114,35.777Z"
                transform="translate(9.568 23.748)"
                fill="#e12726"
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M14,21.5c6.169,5.336,11.5,10,14.673,16.34C37.51,23.834,51.683,26.5,51.683,26.5,44.18,20.333,30.007,27.336,14,21.5Z"
                transform="translate(9.343 14.349)"
                fill="#f58220"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M51.856,27.679C24.177,19.675,8.5,8.5,0,0,.667,6.169,5.5,20.175,14.84,27.012c20.009,14.673,40.851-.167,50.522,15.006C65.862,38.183,64.194,31.18,51.856,27.679Z"
                transform="translate(0 0)"
                fill="#faa21b"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M24.2,39.506c0,1.834.667,6.5-1,10.5v.167c0,.167.167.333.5,0C27.035,46.009,35.372,30.5,42.041,26,34.538,26.333,24.034,37,24.2,39.506Z"
                transform="translate(15.483 17.352)"
                fill="#f05f45"
              />
            </g>
            <path
              id="Shape"
              d="M81.8,24.5h3.668V49.347H81.8Zm1.834-8a2.334,2.334,0,1,0,2.334,2.334A2.279,2.279,0,0,0,83.634,16.5Z"
              transform="translate(54.258 11.012)"
              fill="#343e48"
            />
            <g
              id="Group_3"
              data-name="Group 3"
              transform="translate(75.699 68.196)"
            >
              <path
                id="Path-6"
                data-name="Path"
                d="M0,.1H1.334L5.5,5.6V.1H6.836V7.937H5.669L1.334,2.268V7.937H0V.1Z"
                transform="translate(0 0.067)"
              />
              <path
                id="Shape-2"
                data-name="Shape"
                d="M9.935.1h1.334l3.5,8h-1.5l-.834-2H8.768L7.934,7.937H6.6Zm2,4.669L10.6,1.6,9.268,4.769Z"
                transform="translate(4.405 0.067)"
              />
              <path
                id="Path-7"
                data-name="Path"
                d="M12.7.1h1.5l2.334,6,2.334-6h1.5l-3.335,8H15.868Z"
                transform="translate(8.476 0.067)"
              />
              <path
                id="Path-8"
                data-name="Path"
                d="M19.9.1h1.334V7.937H19.9Z"
                transform="translate(13.281 0.067)"
              />
              <path
                id="Path-9"
                data-name="Path"
                d="M23.7,4.168h0A4.091,4.091,0,0,1,27.7,0a4.351,4.351,0,0,1,3,1l-.834,1A4.17,4.17,0,0,0,27.7,1.334a2.645,2.645,0,0,0-2.5,2.835h0A2.585,2.585,0,0,0,27.868,7,2.277,2.277,0,0,0,29.7,6.336v-1.5h-2V3.668h3.335V7A5.021,5.021,0,0,1,27.7,8.17,3.95,3.95,0,0,1,23.7,4.168Z"
                transform="translate(15.817)"
              />
              <path
                id="Shape-3"
                data-name="Shape"
                d="M34.035.1h1.334l3.5,8h-1.5l-.834-1.834H32.868l-.834,1.667H30.7Zm2,4.669L34.7,1.6,33.368,4.769Z"
                transform="translate(20.489 0.067)"
              />
              <path
                id="Path-10"
                data-name="Path"
                d="M39.6,1.434H37.1V.1h6.336V1.434h-2.5V8.1H39.6Z"
                transform="translate(24.76 0.067)"
              />
              <path
                id="Path-11"
                data-name="Path"
                d="M43.7.1h1.334V7.937H43.7Z"
                transform="translate(29.165 0.067)"
              />
              <path
                id="Shape-4"
                data-name="Shape"
                d="M47.5,4.168h0A4.126,4.126,0,0,1,51.668,0a3.991,3.991,0,0,1,4.168,4h0a3.991,3.991,0,0,1-4.168,4A3.772,3.772,0,0,1,47.5,4.168Zm6.67,0h0A2.778,2.778,0,0,0,51.5,1.334a2.676,2.676,0,0,0-2.668,2.835h0a2.673,2.673,0,1,0,5.336,0Z"
                transform="translate(31.701)"
              />
              <path
                id="Path-12"
                data-name="Path"
                d="M54.9.1h1.334L60.4,5.6V.1h1.334V7.937H60.569L56.234,2.268V7.937H54.9Z"
                transform="translate(36.639 0.067)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SwiftLogo
