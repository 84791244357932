const local = {
  portalApi: "http://localhost:8081",
  partnerApi: "http://localhost:8887",
  kratosApi: "http://localhost:4433",
  apiDocs: "http://localhost:8091",
}
const dev = {
  portalApi: "https://api.portalui-changeme.dev.swiftnav.com",
  partnerApi: "https://api.partners.dev.swiftnav.com",
  kratosApi: "https://portal-identity.dev.swiftnav.com",
  apiDocs: "https://docs.partners.dev.swiftnav.com/swaggerui-partners/",
}

const staging = {
  portalApi: "https://api.portal.staging.swiftnav.com",
  partnerApi: "https://api.partners.staging.swiftnav.com",
  kratosApi: "https://portal-identity.staging.swiftnav.com",
  apiDocs: "https://docs.partners.staging.swiftnav.com/swaggerui-partners/",
}

const prod = {
  portalApi: "https://api.portal.swiftnav.com",
  partnerApi: "https://api.partners.swiftnav.com",
  kratosApi: "http://portal-identity.swiftnav.com",
  apiDocs: "https://docs.partners.swiftnav.com/swaggerui-partners/",
}

const getConfig = () => {
  const { hostname } = window.location
  if (hostname === "portal2.dev.swiftnav.com") {
    return dev
  } else if (hostname === "portal2.staging.swiftnav.com") {
    return staging
  } else if (hostname === "portal2.swiftnav.com") {
    return prod
  }
  return local
}

const config = getConfig()

export default config
