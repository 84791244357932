import { Configuration, FrontendApi } from "@ory/client"

import APIConfig from "./config"

const basePath = APIConfig.kratosApi

export const orySDK = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
)
