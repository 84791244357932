import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
import React, { useEffect, useState } from "react"

import PageNotFound from "@/components/PageNotFound"
import type { AuthContext } from "@/contexts/auth"
import { SideNavContext } from "@/contexts/sideNav"

interface RouterContext {
  auth: AuthContext
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => {
    const [sideNavOpen, toggleSideNav] = useState(false)

    useEffect(() => {
      toggleSideNav(!sideNavOpen)
    }, [toggleSideNav])
    return (
      <>
        <SideNavContext.Provider value={{ sideNavOpen, toggleSideNav }}>
          <Outlet />
        </SideNavContext.Provider>
      </>
    )
  },
  notFoundComponent: PageNotFound,
})
